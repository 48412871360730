@font-face {
  font-family: "neo-sans";
  src: url("../fonts/GOArabicFont/NeoSansArabicRegular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "neo-sans";
  src: url("../fonts/GOArabicFont/NeoSansArabicBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "neo-sans";
  src: url("../fonts/GOArabicFont/NeoSansArabicLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "neo-sans";
  src: url("../fonts/GOArabicFont/NeoSansArabicMedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
