body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 0px;
}

* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.bg-img {
  background-image: url("../images/Pattern.svg");
  background-size: contain;
  background-repeat: round;
  background-attachment: scroll;
}

.text-gradient {
  background: radial-gradient(
      76.22% 78.64% at 60.25% 21.23%,
      #e8f4ff 0.89%,
      #c9eaff 12.5%,
      #b8deff 27.6%,
      #97cfff 42.04%,
      #5eb5ff 55.12%,
      #2b9eff 71.54%,
      #008aff 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.slide-up {
  animation: 2s slide-up;
}

.slide-down {
  animation: 2s slide-down;
}

.slide-in {
  animation: 2s slide-in;
}

.up-down-animation {
  animation: 3s infinite alternate slide-up;
}

@keyframes slide-up {
  from {
    transform: translateY(8px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-down {
  from {
    transform: translateY(-15px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-20px);
  }
  to {
    transform: translateX(0);
  }
}
